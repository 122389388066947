<template>
	<transition name="chat">
		<div
			class="restaurant-chat"
			v-show="isChatActive"
		>
			<div class="restaurant-chat__container">
				<div class="restaurant-chat__top-wrapper">
					<div class="restaurant-chat__top">
						<div class="restaurant-chat__top-name">
							<h1>Чат</h1>
						</div>
						<div class="restaurant-chat__top-button">
							<div class="restaurant-chat__top-button-close">
								<img
									src="@/assets/img/close.svg"
									alt="alt"
									@click="toggleChatPopup()"
								>
							</div>
						</div>
					</div>
					<div class="restaurant-chat__top-delimiter">
					</div>
				</div>
				<div
					class="restaurant-chat__content"
					ref="chatContent"
				>
					<div
						class="loading"
						ref="loading"
						v-if="(pages != page)"
					>Загрузка...
					</div>
					<ChatMessage :contentRef="this.$refs.chatContent"/>
				</div>
				<div class="restaurant-chat__message-form">
					<ChatMessageForm @sendMessage="sendMessage($event)"/>
				</div>
			</div>
			<!--			не удалил этот комментарий потому что могу захотеть вернуться к этому коду-->

			<!--			<div class="restaurant-chat__notify-wrapper">-->
			<!--				<div class="restaurant-chat__notify-inside">-->
			<!--                    <div class="restaurant-chat__notify-bgr">-->
			<!--                        <div class="restaurant-chat__notify-circle">-->
			<!--                        </div>-->
			<!--                        <img src="@/assets/img/chatbgr.svg" alt="alt">-->
			<!--                    </div>-->
			<!--					<div class="restaurant-chat__notify-icons" @click="toggleChatPopup()">-->
			<!--						<div>-->
			<!--							<svg width="24" height="24" viewBox="0 0 24 24" fill="none"-->
			<!--							     xmlns="http://www.w3.org/2000/svg">-->
			<!--								<path fill-rule="evenodd" clip-rule="evenodd"-->
			<!--								      d="M5.5146 15.9998L6.6946 14.8178C7.0726 14.4398 7.2806 13.9378 7.2806 13.4038V8.72683C7.2806 7.36983 7.8706 6.07283 8.9006 5.17083C9.9386 4.26083 11.2606 3.86083 12.6376 4.04183C14.9646 4.35083 16.7196 6.45483 16.7196 8.93683V13.4038C16.7196 13.9378 16.9276 14.4398 17.3046 14.8168L18.4856 15.9998H5.5146ZM13.9996 18.3408C13.9996 19.2398 13.0836 19.9998 11.9996 19.9998C10.9156 19.9998 9.9996 19.2398 9.9996 18.3408V17.9998H13.9996V18.3408ZM20.5206 15.2078L18.7196 13.4038V8.93683C18.7196 5.45583 16.2176 2.49883 12.8996 2.05983C10.9776 1.80383 9.0376 2.39083 7.5826 3.66683C6.1186 4.94883 5.2806 6.79283 5.2806 8.72683L5.2796 13.4038L3.4786 15.2078C3.0096 15.6778 2.8706 16.3768 3.1246 16.9898C3.3796 17.6038 3.9726 17.9998 4.6366 17.9998H7.9996V18.3408C7.9996 20.3588 9.7936 21.9998 11.9996 21.9998C14.2056 21.9998 15.9996 20.3588 15.9996 18.3408V17.9998H19.3626C20.0266 17.9998 20.6186 17.6038 20.8726 16.9908C21.1276 16.3768 20.9896 15.6768 20.5206 15.2078Z"-->
			<!--								      fill="#40AE49"/>-->
			<!--							</svg>-->
			<!--						</div>-->
			<!--					</div>-->
			<!--				</div>-->
			<!--			</div>-->
		</div>
	</transition>
</template>

<script>
import {mapGetters} from "vuex";
import ChatMessageForm from "./ChatMessageForm.vue";
import ChatMessage from "./ChatMessage.vue";

export default {
	components: {ChatMessageForm, ChatMessage},
	data()
	{
		return {
			socketIo: "",
			page: 1,
		};
	},
	computed: {
		...mapGetters({
			isChatActive: "chat/getChatStatus",
			getFile: "chat/getFiles",
			pages: "chat/getPageCount",
			getFilePaths: "chat/getFilePaths",
		}),
	},
	methods: {
		/*
		 ** Инициализация объекта слежения
		 ** Используется для пагинации сообщений
		 */
		initObserver()
		{
			const options =
				{
					root: this.$refs.chatContent,
					rootMargin: "0px",
					threshold: 0.5,
				};
			const callback = (entries) =>
			{
				entries.forEach((entry) =>
				{
					if (entry.isIntersecting && (this.page < this.pages))
					{
						this.page++;
						this.loadMessages(this.page);
					}
				});
			};
			const observer = new IntersectionObserver(callback, options);
			observer.observe(this.$refs.loading);
		},
		/*
		 **  Отправка файла или сообщения по сокету
		 **  Одновременно отправлять нельзя
		 */
		async sendMessage(message)
		{
			// Если нет ключа как файла значит сообщение
			if (!message.lastModified)
			{
				this.$socket.io.emit(
					"support-chat",
					{
						message: message,
					},
				);
			} else if (message.type.includes('image') || message.type.includes('video') || message.type.includes('application'))
			{
				await this.uploadFile(message)
			}
			this.$store.commit("chat/addMessage", {
				text: message,
				datetime: new Date(),
				fromSupport: false,
				file: this.getFilePaths,
			});
			this.$nextTick(() =>
			{
				this.scrollToElement();
			});
		},
		async uploadFile(message)
		{
			await this.$store.dispatch("chat/loadFilesToServ", message);
			this.$socket.io.emit(
				"support-chat",
				{
					files: [this.getFile[0]],
				},
			);
		},
		/*
		 **  Загрузка сообщений,
		 **  скролл к месту между последним старыми сообщением
		 **  и новыми добавленными
		 */
		async loadMessages(page = 1)
		{
			const loadedMessages = await this.$store.dispatch("chat/loadMessagesList", page);
			this.$store.commit("chat/setMessages", loadedMessages);
			const currentScrollHeight = this.$refs.chatContent.scrollHeight;

			if (page != 1)
			{
				await this.$nextTick();
				const newScrollHeight = this.$refs.chatContent.scrollHeight;
				this.$refs.chatContent.scrollTo(0, newScrollHeight - currentScrollHeight);
			}
		},
		/*
		 **  Проскролить в конец всех сообщений
		 */
		scrollToElement()
		{
			const el = this.$refs.chatContent;
			el.scrollTo(0, el.scrollHeight);
		},
		/*
		 ** Открытие / Закрытие попапа чата
		 */
		toggleChatPopup()
		{
			this.$store.commit("chat/setChatStatus", !this.isChatActive);
			this.scrollToElement();
		},
		/*
		 **  Инициализация дааных после открытия
		 **  попапа чата
		 */
		async init()
		{
			await this.loadMessages();
			this.$nextTick(() =>
			{
				this.scrollToElement();
				this.initObserver();
			});
		},
	},
	watch: {
		isChatActive(val)
		{
			if (val && !this.pages)
				this.init();
		},
	},
};

</script>

<style lang="scss">
.restaurant-chat
{
	position: fixed;
	z-index: 3;
	width: 420px;
	background-color: #fff;
	right: 0px;
	height: 100%;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

	&__top
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__content
	{
		height: 100%;
		overflow: auto;
		padding-right: 5px;

		&::-webkit-scrollbar
		{
			margin-left: 17px;
		}

		&::-webkit-scrollbar-thumb
		{
			margin-left: 17px;
		}
	}

	&__container
	{
		padding: 32px 29px 32px 34px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}

	&__top-name
	{
		h1
		{
			font-weight: 700;
			font-size: 24px;
			line-height: 120%;
		}
	}

	&__message-form
	{
		padding-top: 10px;
	}

	&__top-delimiter
	{
		border: 1px solid #E5E5E7;
		margin: 24px 0;
	}

	&__top-button-close
	{
		cursor: pointer;
	}

	&__notify-wrapper
	{
		position: absolute;
		top: 20px;
		left: -47px;

	}

	&__notify
	{
		position: relative;
		overflow: hidden;
		cursor: pointer;
		height: 73px;
	}

	&__notify-messages
	{
		display: flex;
		width: 20px;
		height: 17px;
		font-family: 'Roboto' sans-serif;
		justify-content: center;
		background: #FD470E;
		border-radius: 24px;
		padding: 2px 4px;
		align-items: center;
		position: absolute;
		top: -10px;
		right: 6px;

		.message-count
		{
			font-style: normal;
			font-weight: bold;
			font-size: 11px;
			line-height: 100%;
			color: #fff;
		}
	}

	&__notify-icons
	{
		position: relative;
		cursor: pointer;
		top: -55px;
		margin-left: 13px;
	}

	&__notify-count
	{
		min-width: 18px;
		height: 14px;
		position: absolute;
		padding: 0 4px;
		top: -9px;
		left: 10px;
		font-family: $mainFontNew;
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 14px;
		text-align: center;
		color: #FFFFFF;
		background: #FD470E;
		border-radius: 14px;
	}
}

// Media
@media screen and (max-width: 425px)
{
	.restaurant-chat
	{
		width: 100%;

		.message-count
		{
			font-style: normal;
			font-weight: bold;
			font-size: 11px;
			line-height: 100%;
			color: #fff;
		}
	}
}

.restaurant-chat__notify-background
{
	position: relative;

	.background-circle
	{
		position: absolute;
	}
}

.restaurant-chat__notify-wrapper
{
	.restaurant-chat__notify-icons
	{
		position: relative;
		cursor: pointer;
		top: -56px;
		margin-left: 13px;
	}

	.restaurant-chat__notify-inside
	{
		.restaurant-chat__notify-icons
		{
			top: 18px;

			.restaurant-chat__notify-messages
			{
				right: -5px;
			}
		}

		.restaurant-chat__notify-bgr
		{
			position: absolute;
			top: -7px;

			.restaurant-chat__notify-circle
			{
				height: 38px;
				width: 38px;
				position: absolute;
				background: #F8F8F8;
				border-radius: 50%;
				left: 6px;
				top: 18px;
			}
		}
	}
}

// Media
@media screen and (max-width: 425px)
{
	.restaurant-chat
	{
		width: 100%;

		&__container
		{
			padding: 15px 12px;
		}
	}
}


// Animation

.chat-enter-active, .chat-leave-active
{
	transition: all .4s;
}

.chat-enter, .chat-leave-to
{
	transform: translateX(100px);
	opacity: 0;
}
</style>
