<template>
	<div class="left-block">
		<div class="left-menu-block">
			<router-link
				to="/"
				class="logo-block"
			/>
			<div
				class="menu-item-block"
				:class="{'active': $route.name === 'lk_orders'}"
			>
				<router-link
					:to="{name:'lk_orders'}"
					class="menu-item-name"
				>
					<div class="menu-item-circle">
						<div class="basket-icon menu-icons"></div>
						<div
							class="orders-count-label"
							v-if="ordersCount"
						>{{ ordersCount }}
						</div>
					</div>
					Заказы
				</router-link>
			</div>
			<div
				class="menu-item-block"
				:class="{'active': $route.name === 'lk_menu'}"
			>
				<router-link
					:to="{name:'lk_menu'}"
					class="menu-item-name"
					@click.native="setCalendar(false);setProductCalendar(false)"
				>
					<div class="menu-item-circle">
						<div class="grid-icon menu-icons"></div>
					</div>
					Меню
				</router-link>
			</div>
			<div
				class="menu-item-block"
				:class="{'active': $route.name === 'lk_restaurant'}"
				v-if="access"
			>
				<router-link
					:to="{name:'lk_restaurant'}"
					class="menu-item-name"
					@click.native="setCalendar(false);setProductCalendar(false)"
				>
					<div class="menu-item-circle">
						<div class="hat-icon menu-icons"></div>
					</div>
					Ресторан
				</router-link>
			</div>
			<div
				class="menu-item-block"
				:class="{'active': $route.name === 'lk_bills'}"
				v-if="access"
			>
				<router-link
					:to="{name:'lk_bills'}"
					class="menu-item-name"
					@click.native="setCalendar(false);setProductCalendar(false)"
				>
					<div class="menu-item-circle">
						<div class="wallet-icon menu-icons"></div>
					</div>
					Счета
				</router-link>
			</div>
			<!--            <div class="menu-item-block" :class="{'active': $route.name === 'lk_couriers'}">-->
			<!--                <router-link :to="{name:'lk_couriers'}" class="menu-item-name" @click.native="setCalendar(false);setProductCalendar(false)">-->
			<!--                    <div class="menu-item-circle">-->
			<!--                        <div class="bicycle-icon menu-icons"></div>-->
			<!--                    </div>-->
			<!--                    Курьеры-->
			<!--                </router-link>-->
			<!--            </div>-->
			<!--            <div class="menu-item-block" :class="{'active': $route.name === 'lk_service'}">
							<router-link :to="{name:'lk_service'}" class="menu-item-name" @click.native="setCalendar(false);setProductCalendar(false)">
								<div class="menu-item-circle">
									<div class="service-icon menu-icons"></div>
								</div>
								Сервис
							</router-link>
						</div>-->
			<div
				class="menu-item-block"
				:class="{'active': $route.name === 'lk_personal'}"
				v-if="access"
			>
				<router-link
					:to="{name:'lk_personal'}"
					class="menu-item-name"
					@click.native="setCalendar(false);setProductCalendar(false)"
				>
					<div class="menu-item-circle">
						<div class="workers-icon menu-icons"></div>
					</div>
					Персонал
				</router-link>
			</div>
			<div
				class="menu-item-block"
				:class="{'active': $route.name === 'lk_mydishes'}"
				v-if="access"
			>
				<router-link
					:to="{name:'lk_mydishes'}"
					class="menu-item-name"
					@click.native="setCalendar(false);setProductCalendar(false)"
				>
					<div class="menu-item-circle">
						<div class="mydishes-icon menu-icons"></div>
					</div>
					Мои блюда
				</router-link>
			</div>
			<!--            <div class="menu-item-block" :class="{'active': $route.name === 'lk_reports'}">-->
			<!--                <router-link :to="{name:'lk_reports'}" class="menu-item-name" @click.native="setCalendar(false);setProductCalendar(false)">-->
			<!--                    <div class="menu-item-circle">-->
			<!--                    </div>-->
			<!--                    Отчеты-->
			<!--                </router-link>-->
			<!--            </div>-->
			<!--            <div class="menu-item-block" :class="{'active': $route.name === 'lk_chat'}">-->
			<!--                <router-link :to="{name:'lk_chat'}" class="menu-item-name" @click.native="setCalendar(false);setProductCalendar(false)">-->
			<!--                    <div class="menu-item-circle">-->
			<!--                        <div class="chat-icon menu-icons"></div>-->
			<!--                    </div>-->
			<!--                    Чат-->
			<!--                </router-link>-->
			<!--            </div>-->
			<div
				class="menu-item-block"
				v-if="$store.state.user.IS_RST_OWNER"
				@click="$store.commit('setCurrentOwnerRestaurant', false)"
			>
				<div class="menu-item-circle">
					<div class="change-icon menu-icons"></div>
				</div>
				<div class="menu-item-name">Изменить<br>ресторан</div>
			</div>
			<div
				class="menu-item-block сhat-item-block"
				:class="{'active': $route.name === 'lk_chat'}"
				v-if="isChatVisible === true"
			>
				<span
					class="сhat-item-block__count"
					v-if="messageCount > 0"
				>{{ messageCount }}</span>
				<div
					class="menu-item-name"
					@click="toggleChatPopup()"
				>
					<div class="menu-item-circle">
						<div class="bell-icon menu-icons"></div>
					</div>
					<div class="chat-wrapper">
						Чат
					</div>
				</div>
			</div>
			<div class="menu-item-block bottom">
				<div
					class="menu-item-name"
					@click="logOut()"
				>
					<div class="menu-item-circle">
						<div class="quit-icon menu-icons"></div>
					</div>
					Выйти
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapMutations, mapState, mapActions, mapGetters} from "vuex";

export default {
	name: "RestMenu",
	props: ["ordersCount", "currentTab"],
	data()
	{
		return {
			chatStatus: this.$store.state.chat.isChatActive,
		};
	},
	computed:
		{
			messageCount()
			{
				if (this.$store.state.chat.isChatActive === true)
				{
					return this.$store.commit('chat/resetMessageCount');
				} else
				{
					return this.$store.state.chat.messageCount;
				}
			},
			access()
			{
				return this.$store.state.user.IS_RST_OWNER || this.$store.state.user.IS_RST_MANAGER;
			},
			...mapGetters({
				isChatActive: "chat/getChatStatus",
			}),
			isChatVisible()
			{
				const response = this.$store.state.chat.isChatVisible;
				return response;
			},
		},
	methods:
		{
			async logOut()
			{
				await this.$store.dispatch("logout");
				window.location.reload();
			},
			...mapMutations("lkmenu", ["setCalendar", "setProductCalendar"]),

			toggleChatPopup()
			{
				this.$store.commit("chat/setChatStatus", !this.isChatActive);
			},
		},
};
</script>

<style
	scoped
	lang="scss"
>
.left-block
{
	height: 100%;
	overflow: hidden;
	max-width: 72px;
}

.left-menu-block
{
	position: fixed;
	top: 0;
	background-color: #FFFFFF;
	border: 1px solid #F0F0F0;
	box-sizing: border-box;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
	padding: 24px 12px 24px 12px;
	overflow-y: auto;
	height: 100%;
	display: grid;
	grid-template-rows: repeat(7, max-content) 1fr;
	width: 72px;
	overflow-x: hidden;

	@media (max-height: 500px)
	{
		padding: 5px;
		overflow-x: hidden;
	}

	@media (min-width: 1300px)
	{
		padding: 0;
		margin: 0;
		overflow-x: hidden;
	}
}


.logo-block
{
	width: 48px;
	height: 52px;
	background-image: url('~@/assets/img/logo.svg');
	background-size: cover;
	border: none;
	box-shadow: none;
	margin: 0 auto;
	margin-bottom: 42px;
	display: block;

	@media (max-height: 500px)
	{
		margin-bottom: 5px;
	}
	@media (min-width: 1300px)
	{
		margin-top: 24px;
	}
}

.bell-icon
{
	mask-image: url('~@/assets/img/bell.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
}

.chat-wrapper
{
	position: relative;

	.restaurant-chat__notify-messages
	{
		top: -42px;
		right: 14px;
	}
}

.change-icon
{
	mask-image: url('~@/assets/img/arrow.svg');
	mask-size: cover;
	background-color: #66BE6D;
	width: 25px;
	height: 25px;
	margin: auto;
}

.menu-item-block
{
	text-align: center;
	margin-bottom: 16px;
	position: relative;
	padding-top: 2px;
	cursor: pointer;

	@media (max-height: 500px)
	{
		margin-bottom: 5px;
	}

	@media (min-width: 1300px)
	{
		max-height: 90px;

		&.active
		{
			background: #F0F8F0;

			&:after
			{
				content: '';
				position: absolute;
				height: 100%;
				width: 2px;
				background: #40AE49;
				top: 0;
				right: 0;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}
		}

		padding-top: 8px;
	}

	@media (min-width: 1300px)
	{
		max-height: 90px;
		padding: 5px;

		&.active
		{
			background: #F0F8F0;

			&:after
			{
				content: '';
				position: absolute;
				height: 100%;
				width: 2px;
				background: #40AE49;
				top: 0;
				right: 0;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}
		}
	}
}

.menu-item-block.bottom
{
	background-color: #FFFFFF;
	margin-bottom: 0;
}

.menu-item-block.active .menu-item-circle, .menu-item-block:hover .menu-item-circle
{
	background: #66BE6D;
}

.menu-item-block.active .menu-icons,
.menu-item-block:hover .menu-icons
{
	background: #FFFFFF;
}

.menu-item-block.active .menu-item-name, .menu-item-block:hover .menu-item-name
{
	color: #66BE6D;
}

.menu-item-circle
{
	width: 40px;
	height: 40px;
	background: #F8F8F8;
	border-radius: 50%;
	text-align: center;
	margin: 0 auto;
	padding: 8px;
	position: relative;
}

.basket-icon
{
	mask-image: url('~@/assets/img/basket.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
}

.grid-icon
{
	mask-image: url('~@/assets/img/grid.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
}

.settings-icon
{
	mask-image: url('~@/assets/img/settings.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
}

.quit-icon
{
	mask-image: url('~@/assets/img/power.svg');
	background: #231F20;
	width: 24px;
	height: 24px;
	margin: auto;

	@media (min-width: 1300px)
	{
		background: #979797;
	}
}

.hat-icon
{
	mask-image: url('~@/assets/img/cookingHat.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
	margin: auto;
}

.wallet-icon
{
	mask-image: url('~@/assets/img/wallet.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
	margin: auto;
}

.bicycle-icon
{
	mask-image: url('~@/assets/img/bicycle.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
	margin: auto;
}

.service-icon
{
	mask-image: url('~@/assets/img/service.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
	margin: auto;
}

.workers-icon
{
	mask-image: url('~@/assets/img/person.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
	margin: auto;
}

.mydishes-icon
{
	mask-image: url('~@/assets/img/mydishes.svg');
	background-color: #66BE6D;
	width: 24px;
	height: 24px;
	margin: auto;
}

.сhat-item-block
{
	position: relative;

	&__count
	{
		min-width: 18px;
		height: 14px;
		position: absolute;
		padding: 0 4px;
		top: 5px;
		left: 34px;
		font-family: $mainFontNew;
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 14px;
		text-align: center;
		color: #FFFFFF;
		background: #FD470E;
		border-radius: 14px;
		z-index: 1;
	}
}

.chat-icon
{
	background-image: url('~@/assets/img/message.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #66BE6D;
	width: 24px;
	height: 22px;
	margin: auto;
}

.change-icon
{
	mask-image: url('~@/assets/img/arrow.svg');
	mask-size: cover;
	background-color: #66BE6D;
	width: 25px;
	height: 25px;
	margin: auto;
}

.menu-item-name
{
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 16px;
	text-align: center;
	color: #979797;
	cursor: pointer;
}

.orders-count-label
{
	border-radius: 100px;
	min-width: 14px;
	height: 14px;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #FFFFFF;
	padding-left: 3px;
	padding-right: 3px;
	background: #FD470E;
	position: absolute;
	top: 0;
	left: 27px;
}
</style>
