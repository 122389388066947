<template>
	<div v-if="messages">
		<div
			class="message"
			v-for="message, i in messages"
			:key="'message-item-' + i"
			:class="{'message-sender': !message.fromSupport }"
			ref="messageContent"
		>
			<div
				class="message__user"
				v-if="(!!message.text || !!message.file)"
			>
				<div class="message__user-from">
					<span class="user-name">{{ message.name }}</span>
					<span class="user-delivery-time">{{ dateLocal(message.datetime) }}</span>
				</div>
				<div class="message__user-text">
					<img
						:src="message.file "
						v-if="message.file && isFileImg(message.file)"
						class="message__user-file"
					>
					<a
						:href="message.file"
						v-if="message.file && !isFileImg(message.file)"
						class="message__user-doc"
					>{{ message.file }}</a>
					<p v-if="!message.file">{{ message.text }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	computed: {
		...mapGetters({
			messages: 'chat/getMessages',
		})
	},
	methods:
		{
			dateLocal(date)
			{
				return new Date(date).toLocaleDateString();
			},
			isFileImg(file)
			{
				if (file.startsWith('data:image/'))
					return true;

				if (file.startsWith('data:'))
					return false;

				return 'jpg,bmp,jpeg,jpe,gif,png,webp'.split(',').indexOf(file.split('.').pop()) >= 0;
			},
		},
}
</script>

<style lang="scss">
.message
{
	float: left;

	&__user
	{
		.message__user-text
		{
			padding: 12px 20px;
			background: #F3F3F3;
			border-radius: 0px 24px 24px 24px;
			width: 311px;
			margin-bottom: 12px;

			p
			{
				color: #000;
				font-weight: 400;
				font-size: 16px;
			}
		}

		.message__user-from
		{
			color: #979797;
			font-size: 12px;
			font-weight: 600;

			.user-name
			{
				margin-right: 5px;
				font-weight: 600;
			}
		}
	}
}

// Стили сообщения отправителя
.message__user-file
{
	height: 50px;
	width: 50px;
	object-fit: cover;
}

.message-sender
{
	float: right;

	.message__user-from
	{
		text-align: right;
		font-weight: 600;
	}

	.message__user-text
	{
		background: #F0F8F0;
		border-radius: 24px 0 24px 24px;
	}
}

.message__user-doc
{
	overflow: hidden;
	display: block;
}

.virtual
{
	position: absolute;
	height: 220px;
	top: -200px;
	width: 100%;
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 375px)
{
	.message
	{
		.message__user-text
		{
			width: 250px;
		}
	}
}
</style>
