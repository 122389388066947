<template>
	<div
		:class="$s.container"
		v-if="list"
	>
		<a
			:href="baseUrl"
			:class="$s.logoBlock"
		>
			<div :class="$s.logo"></div>
		</a>
		<div :class="$s.info">Вы являетесь владельцем более одного ресторана</div>
		<PersonalAdd
			:label="'Выберите ресторан'"
			:list="list"
			@setSelected="selectRestaurant"
		/>
	</div>
</template>

<script>
export default {
	props: ['list'],
	components: {
		PersonalAdd: () => import('@/components/block/Rest/PersonalAdd'),
	},
	data()
	{
		return {
			ownerRestaurants: false,
			baseUrl: process.env.BASE_URL,
		}
	},
	methods: {
		selectRestaurant(val)
		{
			this.$store.commit("setCurrentOwnerRestaurant", val.value);
		},
		async fillOwnerRestaurants()
		{
			let res = await this.$axios.get('/lk/getOwnerRestaurants/');
			if (res.data.success)
			{
				this.$store.commit("setAllCurrentOwnerRestaurants", res.data.restaurants);
				if (this.$store.state.allCurrentOwnerRestaurants.length == 1)
					this.$store.commit("setCurrentOwnerRestaurant", this.$store.state.allCurrentOwnerRestaurants[0].value);
			}
		},
	},
	mounted()
	{
		if (this.$store.state.user.IS_RST_OWNER && !this.$store.state.currentOwnerRestaurant)
		{
			let curOwnerRst = localStorage.getItem('currentOwnerRestaurant');
			if (curOwnerRst)
			{
				this.$store.commit("setCurrentOwnerRestaurant", curOwnerRst);
			} else if (!this.$store.state.allCurrentOwnerRestaurants)
			{
				this.fillOwnerRestaurants();
			}
		}
	}
}
</script>

<style
	module="$s"
	lang="scss"
>
.container
{
	width: 400px;
	margin: 40px auto;
}

.droplist
{

	width: 300px;
	height: 50px;
	overflow: scroll;
}

.info
{
	width: 100%;
	margin-top: 40px;
	margin-bottom: 40px;
	color: $greyText;
	font-size: 20px;
	text-align: center;
}

.logo
{
	background-image: url('~@/assets/img/logo-vib.svg');
	position: relative;
	width: 152px;
	height: 50px;
	margin: 0 auto;
}

.logoBlock
{
	width: 100%;
	height: 100px;
	padding: 32px 0px;
	background: #FFFFFF;
	display: block;
}
</style>
